import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

import { StateService } from '../../services/state.service';

@Component({
  selector: 'deinsport-error-message',
  templateUrl: './error-message.component.html',
  styleUrls: ['./error-message.component.scss']
})
export class ErrorMessageComponent implements OnInit {
  @Input() public message: string;

  public message$: Observable<string | null>;

  /**
   * Constructor of the state service.
   *
   * @param stateService
   */
  constructor(
    private readonly stateService: StateService
  ) { }

  /**
   *
   */
  public ngOnInit(): void {
    this.message$ = this.stateService.watchErrorMessage();
  }
}
