<!-- Only show header, if user is not on login route -->
<div class="header" *ngIf="router.url !== '/student' && router.url !== '/admin'">

  <!-- Only show header navigation, if user is authorized -->
  <nav class="nav-admin fixed-top navbar navbar-expand-lg navbar-light bg-white">
    <a class="navbar-brand mt-2 mt-lg-4 ms-2 ms-lg-5 header-logo" href="/">
      <img class="h-100 w-auto" src="../assets/images/logo.png">
    </a>
    <button *ngIf="authLevel > 1" (click)="toggleNavbarCollapsing()" class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="collapse" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>

    <div [class.collapse]="isNavbarCollapsed" class="collapse navbar-collapse" id="navbarSupportedContent">
      <div class="navbar-nav" *ngIf="authLevel > 1">
        <a class="nav-admin-link" routerLink="/admin/clubs" routerLinkActive="nav-admin-link-active" *ngIf="authLevel > 2">
          {{ 'COMPONENTS.HEADER.NAVIGATION.CLUBS' | translate }}
        </a>
        <a class="nav-admin-link" routerLink="/admin/users" routerLinkActive="nav-admin-link-active" *ngIf="authLevel > 2">
          {{ 'COMPONENTS.HEADER.NAVIGATION.USERS' | translate }}
        </a>
        <a class="nav-admin-link" routerLink="/admin/schools" routerLinkActive="nav-admin-link-active" *ngIf="authLevel > 2">
          {{ 'COMPONENTS.HEADER.NAVIGATION.SCHOOLS' | translate }}
        </a>
        <a class="nav-admin-link" routerLink="/admin/courses" routerLinkActive="nav-admin-link-active" *ngIf="authLevel <= 2">
          {{ 'COMPONENTS.HEADER.NAVIGATION.COURSES' | translate }}
        </a>
        <a class="nav-admin-link" routerLink="/admin/downloads" routerLinkActive="nav-admin-link-active">
          {{ 'COMPONENTS.HEADER.NAVIGATION.DOWNLOADS' | translate }}
        </a>
        <a class="nav-admin-link" (click)="openLogoutModal(logoutModalTemplate)">
          {{ authLevel > 1 ? ('COMPONENTS.HEADER.NAVIGATION.LOGOUT' | translate) : ('COMPONENTS.HEADER.NAVIGATION.LOGIN' | translate) }}
        </a>
      </div>
    </div>
  </nav>
</div>

<ng-template #logoutModalTemplate>
  <div class="modal-body text-center">
    <p>{{ 'COMPONENTS.HEADER.LOGOUT_MODAL.TEXT' | translate }}</p>

    <button type="button" class="btn btn-danger me-2" (click)="logout()">
      {{ 'COMPONENTS.HEADER.LOGOUT_MODAL.YES' | translate }}
    </button>

    <button type="button" class="btn btn-light btn-modal ms-2" (click)="cancelLogout()">
      {{ 'COMPONENTS.HEADER.LOGOUT_MODAL.NO' | translate }}
    </button>
  </div>
</ng-template>
