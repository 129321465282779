import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

import { CognitoService } from '../services/cognito.service';

@Injectable({
  providedIn: 'root'
})
export class RootGuard implements CanActivate {
  /**
   *
   * @param router
   * @param cognitoService
   */
  constructor(
    private readonly router: Router,
    private readonly cognitoService: CognitoService
  ) {}

  /**
   * Check if a user token is present.
   * If so, redirect the user to the admin page immediately.
   *
   * @returns `true` as default
   */
  public async canActivate(): Promise<boolean> {
    try {
      if (await this.cognitoService.getUserToken()) {
        this.router.navigate(['/admin']);
      }
    } catch (error) {}

    return true;
  }
}
