import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HttpClientJsonpModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ModalModule } from 'ngx-bootstrap/modal';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoadingSpinnerComponent } from './components/loading-spinner/loading-spinner.component';
import { FooterComponent } from './components/footer/footer.component';
import { ErrorMessageComponentModule } from './components/error-message/error-message.component.module';
import { HttpAuthInterceptor } from './interceptors/http-auth.interceptor';
import { CognitoService } from './services/cognito.service';
import { environment } from '../environments/environment';
import { HeaderComponent } from './components/header/header.component';

export const httpLoaderFactory = (http: HttpClient) => new TranslateHttpLoader(http, '../assets/i18n/', '.json');
export const cognitoServiceFactory = (cognitoService: CognitoService) => () => cognitoService.initialize();


@NgModule({
  declarations: [
    AppComponent,
    LoadingSpinnerComponent,
    FooterComponent,
    HeaderComponent
  ],
  imports: [
    BrowserModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpLoaderFactory,
        deps: [HttpClient]
      },
      defaultLanguage: 'de',
      useDefaultLang: true
    }),
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    HttpClientJsonpModule,
    ModalModule.forRoot(),
    LoggerModule.forRoot({
      level: environment.production ? NgxLoggerLevel.ERROR : NgxLoggerLevel.DEBUG
    }),
    ErrorMessageComponentModule
  ],
  providers: [
    { provide: APP_INITIALIZER, useFactory: cognitoServiceFactory, deps: [CognitoService], multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpAuthInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
