<deinsport-error-message *ngIf="errorMessage$ | async"></deinsport-error-message>
<deinsport-loading-spinner *ngIf="isLoading$ | async"></deinsport-loading-spinner>

<deinsport-header></deinsport-header>

<div class="content px-1 px-lg-5 py-5">
  <router-outlet></router-outlet>
</div>

<deinsport-footer [showImages]="true"></deinsport-footer>
