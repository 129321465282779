import { ChangeDetectorRef, Component, OnInit, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { NGXLogger } from 'ngx-logger';

import { AuthLevel } from '../../enums/auth-level.enum';
import { UserGroup } from '../../enums/user-group.enum';
import { CognitoResultEventType } from '../../interfaces/cognito-result.interface';
import { CognitoService } from '../../services/cognito.service';

@UntilDestroy()
@Component({
  selector: 'deinsport-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  public modalRef: BsModalRef;

  public authLevel: AuthLevel;

  public isNavbarCollapsed: boolean;

  constructor(
    public readonly router: Router,
    private readonly cdRef: ChangeDetectorRef,
    private readonly logger: NGXLogger,
    private readonly modalService: BsModalService,
    private readonly cognitoService: CognitoService
  ) {
    this.authLevel = AuthLevel.UNAUTHORIZED;

    this.isNavbarCollapsed = true;
  }

  ngOnInit(): void {
    // Listen for user updates to distinguish the current auth level.
    this.cognitoService.watchUserUpdate().pipe(untilDestroyed(this)).subscribe(async userUpdate => {
      if (!userUpdate) {
        return;
      }

      if (userUpdate.type === CognitoResultEventType.IS_LOGGED_IN || userUpdate.type === CognitoResultEventType.SUCCESS) {
        let userGroups: string[] | null;

        try {
          userGroups = await this.cognitoService.getUserGroups();
        } catch (error) {
          this.logger.error('Unable to get user groups: ', error);

          this.authLevel = AuthLevel.UNAUTHORIZED;
          return;
        }

        if (!userGroups || userGroups.length === 0) {
          this.authLevel = AuthLevel.UNAUTHORIZED;
          return;
        }

        if (userGroups.includes(UserGroup.ADMINISTRATORS)) {
          this.authLevel = AuthLevel.ADMIN;
        } else if (userGroups.includes(UserGroup.COACHES)) {
          this.authLevel = AuthLevel.COACH;
        }
      } else if (userUpdate.type === CognitoResultEventType.SUCCESSFULLY_LOGGED_OUT) {
        this.authLevel = AuthLevel.UNAUTHORIZED;
        this.router.navigate(['/']);
      }

      this.cdRef.detectChanges();
    });
  }

  /**
   *
   * @param logoutModalTemplate
   */
  public openLogoutModal(logoutModalTemplate: TemplateRef<any>): void {
    this.modalRef = this.modalService.show(logoutModalTemplate, { class: 'modal-sm' });
  }

  /**
   *
   */
  public toggleNavbarCollapsing(): void {
    this.isNavbarCollapsed = !this.isNavbarCollapsed;
  }

  /**
   *
   */
  public cancelLogout(): void {
    this.modalRef.hide();
  }

  /**
   *
   */
  public async logout(): Promise<void> {
    try {
      await this.cognitoService.signOutUser();
      this.modalRef.hide();
    } catch (error) {
      this.logger.error(error);
    }
  }
}
