import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { RootGuard } from './guards/root.guard';

const routes: Routes = [
  { path: 'admin', loadChildren: () => import('./pages/admin/admin.page.module').then(m => m.AdminPageModule) },
  // eslint-disable-next-line max-len
  { path: 'student', loadChildren: () => import('./pages/student/student.page.module').then(m => m.StudentPageModule), canActivate: [RootGuard] },
  { path: '', redirectTo: 'student', pathMatch: 'full' },
  { path: '**', redirectTo: 'student', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
