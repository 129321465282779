import { Injectable } from '@angular/core';
import {
  HttpEvent, HttpInterceptor, HttpHandler, HttpRequest
} from '@angular/common/http';
import { from, lastValueFrom, Observable } from 'rxjs';

import { CognitoService } from '../services/cognito.service';

/**
 * HTTP interceptor to apply an access token to each request, if an access token is available.
 */
@Injectable()
export class HttpAuthInterceptor implements HttpInterceptor {
  /**
   * Constructor of the HTTP auth interceptor.
   *
   * @param cognitoService
   */
  constructor(
    private cognitoService: CognitoService
  ) {}

  /**
   *
   * @param request
   * @param next
   *
   * @returns
   */
  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return from(this.handle(request, next));
  }

  /**
   *
   * @param request
   * @param next
   *
   * @returns
   */
  private async handle(request: HttpRequest<any>, next: HttpHandler): Promise<HttpEvent<any>> {
    const accessToken = await this.cognitoService.getUserToken();

    const requestAuthorized = request.clone({
      headers: accessToken ? request.headers.set('Authorization', accessToken) : request.headers
    });

    return lastValueFrom(next.handle(requestAuthorized));
  }
}
