<footer class="container-fluid footer">
  <div class="row mt-2 mt-lg-4">
    <div class="col-12 col-md-6 ps-lg-5" *ngIf="showImages">
      <div class="d-flex justify-content-center justify-content-lg-start align-items-center">
        <img class="w-auto float-start me-3" src="../assets/images/fit4future-foundation.jpg" style="height: 28px;">
        <img class="w-auto float-start ms-3" src="../assets/images/beisheim-stiftung.png" style="height: 28px;">
      </div>
    </div>

    <div class="col-12 col-md-6 pe-lg-5">
      <div class="d-flex justify-content-center justify-content-lg-end align-items-center">
        <a class="me-3 footer-link" href="https://www.deinsport.de/impressum" target="_blank">
          {{ 'COMPONENTS.FOOTER.IMPRINT' | translate }}
        </a>
        <a class="ms-3 footer-link" href="https://www.deinsport.de/datenschutz" target="_blank">
          {{ 'COMPONENTS.FOOTER.PRIVACY' | translate }}
        </a>
      </div>
    </div>
  </div>
</footer>
