export enum CognitoResultEventType {
  SUCCESS = 'success',
  IS_LOGGED_IN = 'isLoggedIn',
  NEW_PASSWORD_REQUIRED = 'newPasswordRequired',
  SUCCESSFULLY_LOGGED_OUT = 'successfullyLoggedOut'
}

export interface ICognitoResult {
  type: CognitoResultEventType;
  data: any;
}
