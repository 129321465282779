import { AfterContentChecked, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

import { StateService } from './services/state.service';

@Component({
  selector: 'deinsport-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterContentChecked {
  public title: string;

  public isLoading$: Observable<boolean>;
  public errorMessage$: Observable<string | null>;

  /**
   * Constructor of the app component.
   *
   * @param router
   * @param cdRef
   * @param stateService
   */
  constructor(
    public readonly router: Router,
    private readonly cdRef: ChangeDetectorRef,
    private readonly stateService: StateService
  ) {
    this.title = 'DeinSport';
  }

  /**
   *
   */
  public ngOnInit(): void {
    this.isLoading$ = this.stateService.watchLoadingState();
    this.errorMessage$ = this.stateService.watchErrorMessage();
  }

  /**
   *
   */
  public ngAfterContentChecked(): void {
    this.cdRef.detectChanges();
  }
}
