export const environment = {
  production: false,
  api: {
    baseUrl: 'https://api-dev.deinsport.de'
  },
  google: {
    maps: {
      apiKey: 'AIzaSyDcLM88ZpWWrMp-gCNVeAJQ-GnoD_krs7E'
    }
  },
  aws: {
    cognito: {
      userPool: {
        userPoolId: 'eu-central-1_Ftc6fqlLU',
        userPoolClientId: '4bqmdd3559td4fh4e6n9abppc3'
      }
    }
  }
};
