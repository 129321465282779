import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AlertModule } from 'ngx-bootstrap/alert';

import { ErrorMessageComponent } from '../../components/error-message/error-message.component';

@NgModule({
  imports: [CommonModule, AlertModule],
  declarations: [ErrorMessageComponent],
  exports: [ErrorMessageComponent]
})
export class ErrorMessageComponentModule {}
