import { Injectable } from '@angular/core';
import { CognitoUser, CognitoUserPool, AuthenticationDetails, CognitoUserSession } from 'amazon-cognito-identity-js';
import { BehaviorSubject, Observable } from 'rxjs';
import { NGXLogger } from 'ngx-logger';

import { CognitoResultEventType, ICognitoResult } from '../interfaces/cognito-result.interface';
import { environment } from '../../environments/environment';
import { UserGroup } from '../enums/user-group.enum';

@Injectable({
  providedIn: 'root'
})
export class CognitoService {
  private cognitoUser: CognitoUser;

  private userPool: CognitoUserPool;

  private sessionUserAttributes: any;

  private userUpdate$: BehaviorSubject<ICognitoResult | undefined>;

  /**
   * Constructor of the Cognito service.
   *
   * @param logger
   */
  constructor(
    private readonly logger: NGXLogger
  ) {
    this.userUpdate$ = new BehaviorSubject<ICognitoResult>(undefined);

    this.userPool = new CognitoUserPool({
      UserPoolId: environment.aws.cognito.userPool.userPoolId,
      ClientId: environment.aws.cognito.userPool.userPoolClientId
    });
  }

  /**
   *
   */
  public async initialize(): Promise<void> {
    this.cognitoUser = this.userPool.getCurrentUser();

    if (this.cognitoUser) {
      try {
        await this.refreshUserSession();
      } catch (error) {
        // This will only be called in APP_INITIALIZER and therefore it is okay to log here
        this.logger.error('CognitoService: Unable to refresh user session: ', error);
      }

      // If there was a cognitoUser, send an event to the subscribers, that the user is logged in.
      this.userUpdate$.next({
        type: CognitoResultEventType.IS_LOGGED_IN,
        data: {}
      });
    }
  }

  /**
   *
   * @returns
   */
  public watchUserUpdate(): Observable<ICognitoResult> {
    return this.userUpdate$.asObservable();
  }

  /**
   * Get the access token as JWT from the current Cognito user session.
   *
   * @returns Access token as JWT token
   */
  public async getUserToken(): Promise<string | null> {
    if (!this.cognitoUser) {
      return null;
    }

    const sessionToken = (await this.getUserSession())?.getAccessToken().getJwtToken();

    return sessionToken && sessionToken.length ? sessionToken : null;
  }

  /**
   * Authenticate the user with the given credentials.
   *
   * @param username Username of the user
   * @param password Password of the user
   * @param options.isTracker Flag, if the user is a tracker
   *
   * @returns Authentication result from Cognito
   */
  public async authenticateCredentials(
    username: string,
    password: string
  ): Promise<ICognitoResult> {
    const response: ICognitoResult = {
      type: CognitoResultEventType.SUCCESS,
      data: {}
    };

    // If there is already a user token, the rest of the function can always be skipped.
    if (await this.getUserToken()) {
      response.type = CognitoResultEventType.IS_LOGGED_IN;
      this.userUpdate$.next(response);
      return response;
    }

    // Create cognito user for given credentials.
    this.cognitoUser = new CognitoUser({
      Username: username.toLowerCase(), // E-mails will always be used lowercased in the backend.
      Pool: this.userPool
    });

    const authDetails = new AuthenticationDetails({
      Username: username,
      Password: password
    });

    return new Promise((resolve, reject) => {
      this.cognitoUser.authenticateUser(authDetails, {
        newPasswordRequired: (userAttributes, requiredAttributes) => {
          response.type = CognitoResultEventType.NEW_PASSWORD_REQUIRED;

          response.data = { userAttributes, requiredAttributes };

          this.sessionUserAttributes = userAttributes;

          this.userUpdate$.next(response);

          resolve(response);
        },
        onSuccess: (result) => {
          response.data = { result };

          this.userUpdate$.next(response);

          resolve(response);
        },
        onFailure: (error) => {
          this.cognitoUser = null;

          reject(error);
        }
      });
    });
  }

  /**
   * Get the given user attribute from the current user session.
   *
   * @param attributeName Attribute name of the attribute to get
   *
   * @returns Attribute value or "null", if it does not exist
   */
  public async getUserAttribute(attributeName: string): Promise<any | null> {
    this.logger.debug(`CognitoService: Get user attribute ${attributeName}`);

    if (!this.cognitoUser) {
      return null;
    }

    return (await this.getUserSession())?.getIdToken().payload[attributeName] || null;
  }

  /**
   *
   * @param newPassword
   *
   * @returns
   */
  public completeNewPasswordChallenge(newPassword: string): Promise<ICognitoResult> {
    const response: ICognitoResult = {
      type: CognitoResultEventType.SUCCESS,
      data: {}
    };

    return new Promise((resolve, reject) => {
      this.cognitoUser.completeNewPasswordChallenge(newPassword, {}, {
        onSuccess: (result) => {
          response.data = { result };

          this.userUpdate$.next(response);

          resolve(response);
        },
        onFailure: (error) => {
          this.cognitoUser = null;

          reject(error);
        }
      });
    });
  }

  /**
   * Sign out the current user.
   */
  public signOutUser(): Promise<void> {
    return new Promise(resolve => {
      if (!this.cognitoUser) {
        return resolve();
      }

      this.cognitoUser.signOut(() => {
        this.userUpdate$.next({
          type: CognitoResultEventType.SUCCESSFULLY_LOGGED_OUT,
          data: {}
        });

        this.cognitoUser = null;

        resolve();
      });
    });
  }

  /**
   * Get the user groups the current user belongs to.
   *
   * @returns User groups as string array
   */
  public async getUserGroups(): Promise<string[]> | null {
    const userGroups = await this.getUserAttribute('cognito:groups');

    return userGroups && userGroups.length ? userGroups : null;
  }

  /**
   *
   * @returns
   */
  public async isUserAdministrator(): Promise<boolean | undefined> {
    return (await this.getUserGroups())?.includes(UserGroup.ADMINISTRATORS);
  }

  /**
   *
   * @returns
   */
  public async isUserCoach(): Promise<boolean | undefined> {
    return (await this.getUserGroups())?.includes(UserGroup.COACHES);
  }

  /**
   * Get the current user session.
   *
   * @returns Current Cognito user session or "null", if session is not available
   */
  private getUserSession(): Promise<CognitoUserSession | null> {
    return new Promise<CognitoUserSession | null>((resolve) => {
      if (!this.cognitoUser) {
        return resolve(null);
      }

      this.cognitoUser.getSession((error: any, session: CognitoUserSession) => {
        if (error) {
          this.logger.warn('CognitoService: Error retrieving user session: ', error);
          return resolve(null);
        }

        if (!session.isValid()) {
          this.logger.warn('CognitoService: User session is invalid');
          return resolve(null);
        }

        resolve(session);
      });
    });
  }

  /**
   *
   * @returns
   */
  private async refreshUserSession(): Promise<CognitoUserSession | null> {
    const session = await this.getUserSession();

    if (!session) {
      return null;
    }

    return new Promise<CognitoUserSession>((resolve, reject) => {
      const refreshToken = session.getRefreshToken();

      this.cognitoUser.refreshSession(refreshToken, (error: any, refreshedSession: CognitoUserSession) => {
        if (error) {
          return reject(error);
        }

        resolve(refreshedSession);
      });
    });
  }
}
