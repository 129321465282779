import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StateService {
  public isLoading$: BehaviorSubject<boolean>;
  public errorMessage$: BehaviorSubject<string | null>;

  /**
   * Constructor of the state service.
   */
  constructor() {
    this.isLoading$ = new BehaviorSubject<boolean>(false);
    this.errorMessage$ = new BehaviorSubject<string | null>(null);
  }

  /**
   *
   * @returns
   */
  public watchLoadingState(): Observable<boolean> {
    return this.isLoading$.asObservable();
  }

  /**
   *
   * @param isLoading
   */
  public setLoadingState(isLoading: boolean): void {
    this.isLoading$.next(isLoading);
  }

  /**
   *
   * @returns
   */
  public watchErrorMessage(): Observable<string | null> {
    return this.errorMessage$.asObservable();
  }

  /**
   * Set the error message.
   * After a specified timeout, reset the error message.
   *
   * @param message
   * @param resetTimeoutInMs
   */
  public setErrorMessage(message: string, resetTimeoutInMs = 5000): void {
    this.errorMessage$.next(message);

    setTimeout(() => {
      this.errorMessage$.next(null);
    }, resetTimeoutInMs);
  }
}
